import { useEffect, useState } from "react";
import Marquee from "react-text-marquee";
import ITournament from "../../../../Interfaces/Tournament";
import ITournamentLobbyLeaderboard from "../../../../Interfaces/TournamentLobbyLeaderboard";
import TournamentService from "../../../../../services/TournamentService";

type Props = {
  tournament: ITournament
  lobby: any
}

const LobbyLeaderboardTab = (props:Props) => {
  const { tournament, lobby } = props;
  const [leaderboard, setLeaderboard] = useState<Array<ITournamentLobbyLeaderboard>>([]);
  const [leaderboardError, setShowLeaderboardError] = useState();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true);
    TournamentService.getLobbyLeaderboard(tournament?.id, lobby.id)
      .then((Rleaderboard) => {
        setLeaderboard(Rleaderboard);
        setLoading(false);
      })
      .catch((error) => {
        setLeaderboard([]);
        setShowLeaderboardError(error.response.data.message);
        setLoading(false);
      });
  }, [tournament, lobby]);
  return (
    <>
      {/* <div className="column col-12">
        <h5 className="skp-text-primary">Leaderboard</h5>
      </div> */}
      {!leaderboardError && !loading && (
        <>
          <div className="column col-12 skp-card skp-text-light">
            <div className="columns m-2">
              <div className="column col-1 col-xs-2 mb-2">#</div>
              <div className="column col-2 mb-2">Team n.</div>
              <div className="column col-5 mb-2 col-xs-6">Team name</div>
              <div
                className="column col-2 mb-2 tooltip text-right"
                data-tooltip="Score"
              >
                Score
              </div>
              <div
                className="column col-1 mb-2 tooltip text-right hide-xs"
                data-tooltip="Wins"
              >
                Wins
              </div>
              <div
                className="column col-1 mb-2 tooltip text-right hide-xs"
                data-tooltip="Kills"
              >
                Kill
              </div>
            </div>
            <div className="columns skp-text-primary m-2">
              {leaderboard &&
                leaderboard
                  .sort((a, b) => (Number(a.score) < Number(b.score) ? 1 : -1))
                  .map((team, index) => (
                    <div className="columns col-12" key={index}>
                      <div className="column col-1 col-xs-2 mb-2">{index + 1}</div>
                      <div className="column col-2 mb-2">{Number(team.teamNum)}</div>
                      <div className="column col-5 col-xs-6 mb-2"><Marquee text={team.teamName} /></div>
                      <div className="column col-2 mb-2 text-right">
                        {team.score}
                      </div>
                      <div className="column col-1 mb-2 text-right hide-xs">
                        {team.wins}
                      </div>
                      <div className="column col-1 mb-2 text-right hide-xs">
                        {team.kills}
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </>
      )}

      {leaderboardError && !loading && <>
        <div className="column col-12 mt-2 mb-2">
          <h6 className="skp-text-light">{leaderboardError}</h6>
          <div className="space-divider-20"></div>
        </div>
      </>}

      {loading && <>
        <div className="column col-12 mt-2 mb-2">
          <div className="loading"></div>
        </div>
      </>}
    </>
  );
};

export default LobbyLeaderboardTab;
