
import { useContext, useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { UserContext } from "../../Context/UserContext";
import TournamentService from "../../../services/TournamentService";
import ICompetition from "../../Interfaces/Competition";
import CompetitionTile from "../../UiLibrary/CompetitionTile";
import { useTranslation } from "react-i18next";

const Competitions = () => {
    const { user } = useContext(UserContext);
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false);
    const [competitions, setCompetitionList] = useState<Array<ICompetition>>([])

    useEffect(() => {
        setLoading(true);
        
        TournamentService.getCompetitions(null).then((Rcompetitions) => {
          setCompetitionList(Rcompetitions);
          setLoading(false);
        });
      }, []);

    return (
        <>
            <div className="skp-full-page">
                <div className="container">
                    <div className="columns">
                        <div className="column col-12 text-center">
                            <h4 className="skp-primary-blue skp-heading ">{t('common.competitions')}</h4>
                        </div>
                    </div>
                    <Scrollbars style={{width: "100%", height: "calc(100vh - 320px)"}} renderView={(props:any) => <div {...props} className="viewOverflowXHidden"/>}>
                        <div className="columns">
                            {/* Tiles */}
                            {competitions.length > 0 &&
                                competitions.slice(0).map((competition: ICompetition) => (
                                <div
                                    className="column col-3 col-xs-12"
                                    key={competition?.id}
                                >
                                    <CompetitionTile
                                        competition={competition}
                                    ></CompetitionTile>
                                </div>
                            ))}
                            {competitions.length === 0 && (
                                <div className="column col-12">
                                    <h6 className="skp-text-primary">{t('competition.noCompetition')}</h6>
                                </div>
                            )}
                        </div>
                    </Scrollbars>
                </div>
            </div>
        </>
    );
};

export default Competitions;
