import { Routes, Route, Navigate } from 'react-router-dom';
import CookiesPolicy from './components/Pages/Legal/CookiesPolicy';
import Logout from './components/Pages/Logout/Logout';
import Home from './components/Pages/Home/Home';
import TermsConditions from './components/Pages/Legal/TermsConditions';
import PrivacyPolicy from './components/Pages/Legal/PrivacyPolicy';
import Favourites from './components/Pages/Favourites/Favourites';
import WarZone2vs2 from './components/Pages/BrandedTournaments/MilanoComics/WarZone2vs2/WarZone2vs2';
import BoxFight from './components/Pages/BrandedTournaments/MilanoComics/BoxFight/BoxFight';
import KillRace from './components/Pages/BrandedTournaments/MilanoComics/KillRace/KillRace';
import PlayPlayTournament from './components/Pages/BrandedTournaments/PlayPlay/PlayPlayTournament';
import WeArenaTournament from './components/Pages/BrandedTournaments/WeArena/WeArenaTournament';
import NintendoTournament from './components/Pages/BrandedTournaments/NintendoTournament';
import Games from './components/Pages/Games/Games';
import GameSingle from './components/Pages/Games/GameSingle';
import Teams from './components/Pages/Teams/Teams';
import TeamsSingle from './components/Pages/Teams/TeamsSingle';
import TeamJoin from './components/Pages/Teams/TeamJoin';
import TeamSettings from './components/Pages/Teams/TeamSettings/TeamSettings';
import Tournaments from './components/Pages/Tournaments/Tournaments';
import TournamentControl from './components/Pages/Tournaments/TournamentControl';
import JoinTournamentPage from './components/Pages/Tournaments/JoinTournament/JoinTournamentPage';
import RenderOnRole from './components/KeyCloack/RenderOnRole';
import YuniteTournaments from './components/Pages/Home/YuniteTournaments';
import YuniteTournamentSingle from './components/Pages/Tournaments/YuniteTournamentSingle';
import User from './components/Pages/Users/User';
import MyTournaments from './components/Pages/Tournaments/MyTournaments/MyTournaments';
import MyTeams from './components/Pages/Teams/MyTeams';
import Payments from './components/Pages/Shop/Payments';
import UserSettings from './components/Pages/Users/UserSettings';
import PlayPlayTeamsRegistration from './components/Pages/BrandedTournaments/PlayPlay/PlayPlayTeamsRegistration';
import TournamentAdmin from './components/Pages/Administration/TournamentAdmin';
import RolesAdmin from './components/Pages/Administration/RolesAdmin';
import ChatsPage from './components/Pages/Chats/ChatsPage';
import Competitions from './components/Pages/Competitions/Competitions';
import Users from './components/Pages/Users/Users';
import BrandPage from './components/Pages/Brand/BrandPage';
import Brands from './components/Pages/Brand/Brands';
import BrandSettings from './components/Pages/Brand/BrandSettings/BrandSettings';
import BrandJoin from './components/Pages/Brand/BrandJoin';
import NewsPage from './components/Pages/Brand/BrandTabs/News/NewsPage';
import FullScreenBracket from './components/Pages/Tournaments/TournamentTabs/FullscreenBracket';

const RoutesRouter = () => {
    return <>
        <Routes>
            <Route path="/branded-tournaments/nintendo-online-event/:spreadsheetId" element={<NintendoTournament />} />
            <Route path="/branded-tournaments/wearena/" element={<WeArenaTournament />} />
            <Route path="/branded-tournaments/play-play/register/:tournamentId" element={<PlayPlayTeamsRegistration />} />
            <Route path="/branded-tournaments/play-play/:tournamentId" element={<PlayPlayTournament />} />
            <Route path="/branded-tournaments/milano-comics/killrace/:game/:sheet" element={<KillRace />} />
            <Route path="/branded-tournaments/milano-comics/killrace/:game" element={<KillRace />} />
            <Route path="/branded-tournaments/milano-comics/killrace/" element={<KillRace />} />
            <Route path="/branded-tournaments/milano-comics/boxfight/" element={<BoxFight />} />
            <Route path="/branded-tournaments/milano-comics/warzone2vs2/" element={<WarZone2vs2 />} />
            <Route path="/competitions" element={<Competitions />} />
            <Route path="/yunite-tournaments/:id/:tab/" element={<RenderOnRole roles={["Yunite Tournament Admin"]}><YuniteTournamentSingle /></RenderOnRole>} />
            <Route path="/yunite-tournaments/:id/" element={<RenderOnRole roles={["Yunite Tournament Admin"]}><YuniteTournamentSingle /></RenderOnRole>} />
            <Route path="/yunite-tournaments" element={<RenderOnRole roles={["Yunite Tournament Admin"]}><YuniteTournaments /></RenderOnRole>} />
            <Route path="/tournament/:id/brackets/fullscreen" element={<FullScreenBracket/>} />
            <Route path="/tournaments/:id/join" element={<JoinTournamentPage />} />
            <Route path="/tournaments/:id/:path/:tab" element={<TournamentControl />} />
            <Route path="/tournaments/:id/:tab" element={<TournamentControl />} />
            <Route path="/tournaments/:id" element={<TournamentControl />} />
            <Route path="/tournaments" element={<Tournaments />} />
            <Route path="/tournament-admin/:id" element={<TournamentAdmin />} />
            <Route path="/roles-admin" element={<RolesAdmin />} />
            <Route path="/chat" element={<ChatsPage/>}/>
            <Route path='/users' element={<Users/>}/>
            <Route path="/users/:id/payments" element={<Payments />} />
            <Route path="/users/:id/my-teams" element={<MyTeams />} />
            <Route path="/users/:id/my-tournaments/:tab" element={<MyTournaments />} />
            <Route path="/users/:id/settings/:tab" element={<UserSettings />} />
            <Route path="/users/:id/:tab/" element={<User />} />
            <Route path="/users/:id/" element={<User />} />
            <Route path="/teams/:id/settings/:tab" element={<TeamSettings />} />
            <Route path="/teams/join-team/:id" element={<TeamJoin />} />
            <Route path="/teams/:id/:tab" element={<TeamsSingle />} />
            <Route path="/teams/:id" element={<TeamsSingle />} />
            <Route path="/teams" element={<Teams/>} />
            <Route path="/brands/:slug/settings/:tab" element={<BrandSettings />} />
            <Route path="/brands/join-brand/:slug" element={<BrandJoin />}/>
            <Route path="/brands/:slug/:tab" element={<BrandPage />} />
            <Route path="/brands/:slug" element={<BrandPage/>}/>
            <Route path="/brands" element={<Brands/>} />
            <Route path="/news/:id" element={<NewsPage />} />
            <Route path="/games/:label" element={<GameSingle />} />
            <Route path="/games" element={<Games />} />
            <Route path="/favourites" element={<Favourites />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsConditions />} />
            <Route path="/cookies-policy" element={<CookiesPolicy />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Navigate to="/home" />} />
        </Routes>
    </>
}

export default RoutesRouter